<template>
  <div class="w-100">
       <v-row>
            <!-- Avatar - name -->
            <v-col xl="3" lg="3" md="4" sx="12" sm="12" style="padding: 2px; height:auto;">
                <v-card id="short-info-avatar">
                    <div class="d-flex justify-center align-center flex-column">
                        <div id="avatar">
                            <v-avatar color="grey lighten-2" size="100">
                                <v-img :src="patient.avatar"></v-img>
                            </v-avatar>
                        </div>

                        <span class="text-h6">{{ patientInfo.fullName }}</span>
                        <span class="text-caption">{{ patientInfo.email }}</span>

                        <div class="service-amount m-2 p-3 w-100">
                            <div class="item">
                                <span class="font-weight-bold">15</span>
                                <span class="text-caption">Pass</span>
                            </div>
                            <div class="item">
                                <span class="font-weight-bold">15</span>
                                <span class="text-caption">Up comming</span>
                            </div>
                        </div>

                        <div class="p-3 w-100">
                            <v-btn block>
                                Send Message
                            </v-btn>
                        </div>
                    </div>
                </v-card>
            </v-col>

            <!-- Patient Information -->
            <v-col xl="9" lg="9" md="8" sx="12" sm="12" style="padding: 2px; height:auto">
                <v-card id="patient-information">

                    <!-- Gender - Birthday - Phone number -->
                    <v-row >
                        <!-- Gender -->
                        <v-col cols="4">
                            <div class="item">
                                <span class="title-info">Gender</span>
                                <span>{{ patient.genderType }}</span>
                            </div>
                        </v-col>
                        <!-- Birthday -->
                        <v-col cols="4">
                            <div class="item">
                                <span class="title-info">Birthday</span>
                                <span>{{ patient.birthDate }}</span>
                            </div>
                        </v-col>
                        <!-- Phone number -->
                        <v-col cols="4">
                            <div class="item">
                                <span class="title-info">Phone number</span>
                                <span>{{ patient.phone }}</span>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <!-- Age -->
                        <v-col cols="4">
                            <div class="item">
                                <span class="title-info">Age</span>
                                <span>{{ patient.age }}</span>
                            </div>
                        </v-col>
                        <!-- AdmissionType -->
                            <v-col cols="4">
                            <div class="item">
                                <span class="title-info">Admission Type</span>
                                <span>{{ patient.admissionType }}</span>
                            </div>
                        </v-col>
                        <!-- Nationality -->
                        <v-col cols="4">
                            <div class="item">
                                <span class="title-info">Nationality</span>
                                <span>{{ patient.nationality }}</span>
                            </div>
                        </v-col>
                    </v-row>

                    <!-- Nationality - Identitty - Insurance -->
                    <v-row >     
                        <!-- Identity/ Passport card -->
                        <v-col cols="4">
                            <div class="item">
                                <span class="title-info">Identity/Passport</span>
                                <span>{{ patient.identificationNumber }}</span>
                            </div>
                        </v-col>
                        <!-- Insurance -->
                        <v-col cols="4">
                            <div class="item">
                                <span class="title-info">Insurance</span>
                                <span>GN02234124552</span>
                            </div>
                        </v-col>
                        <!-- Nationality -->
                        <v-col cols="4">
                            <div class="item">
                                <span class="title-info">Registered Date</span>
                                <span>{{ patient.registerDate }}</span>
                            </div>
                        </v-col>
                    </v-row>

                    <!-- Address -->
                    <v-row>
                        <v-col cols="12">
                            <div class="item">
                                <span class="title-info">Address</span>
                                <span>{{ patient.address }}</span>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
  </div>
</template>

<script>
import moment from "moment";
export default {
    props: ['patientInfo'],
    data:() => {
        return {
            patient: {
                fullName: '',
                avatar: '',
                age: 0,
                address:'',
                genderType: 0,
                birthDate: '',
                email: '',
                identificationNumber: '',
                nationality: '',
                phone: 0,
                admissionType: '',
                registerDate: ''
            }
        }
    },
    
    watch:{
        patientInfo:function() {
            if (this.patientInfo != null) {
                this.patient.fullName = this.patientInfo.fullName;
                this.patient.avatar = this.patientInfo.avatar;
                this.patient.age = this.patientInfo.age;
                this.patient.genderType = (this.patientInfo.genderType == 1) ? 'Male' : 'Female';
                this.patient.address = this.patientInfo.address;
                this.patient.phone = this.patientInfo.phone;
                this.patient.birthDate = moment(this.patientInfo.birthDate).format('DD/MM/YYYY');
                this.patient.email = this.patientInfo.email;
                this.patient.identificationNumber = this.patientInfo.identificationNumber;
                this.patient.admissionType = this.patientInfo.admissionType; 
                this.patient.registerDate = moment(this.patientInfo.registerDate).format('DD/MM/YYYY hh:mm:ss')   
                console.log(this.patient)
            }            
        }
    },
  
}
</script>

<style scoped>


#avatar {
    padding: 15px 5px;
}

#short-info-avatar, #patient-information {
    padding: 10px;
    height: 100%;
}

#short-info-avatar .service-amount{
    display: flex;
    padding: 10px 0px;
}
#short-info-avatar .service-amount .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    width: 50%;
    padding: 10px;
}
#short-info-avatar .service-amount .item:first-child {
    border-right: 2px solid #eef5f9;
}

#short-info-avatar .service-amount .item:last-child {
    border-left: 2px solid #eef5f9;
}

#patient-information .item {
    padding: 5px 30px;
    display: flex;
    width: auto;
    flex-direction: column;
    font-size: 15px;
    text-align: left;
}

#patient-information .item .title-info {
    color: #9099a1;
    font-weight: 500;
}
</style>