<template>
  <div class="box-timing">
    <span class="minutes-process">{{ minutes }} minutes</span>
    <v-progress-linear
      active
      :color="color"
      style="width: 70%"
      value="100"
    ></v-progress-linear>
  </div>
</template>

<script>
export default {
  watch: {},
  props: {
    minutes: Number,
  },
  data() {
    return {
      stateTime: [
        {
          time: 10, // minutes
          color: "green",
        },
        {
          time: 20,
          color: "yellow darken-2",
        },
        {
          time: 30,
          color: "error",
        },
      ],
      color: "red",
    };
  },

  created() {
    var color = () => {
      var findColor = this.stateTime.find((item) => item.time > this.minutes);
      if (typeof findColor !== "undefined") {
        this.color = findColor.color;
      }
    };
    color();
  },
};
</script>

<style scoped>
.box-timing {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: left;
  justify-content: center !important;
}
.box-timing .minutes-process {
  font-weight: 500;
}
</style>