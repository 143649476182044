import HttpClient from '../plugins/httpClient';


import { ApiGateway } from '../plugins/gateway';

var httpClient = new HttpClient(ApiGateway.Emr);

export default {
    getFinishQueue: async() => {
        return httpClient.get(`/Queue/Finish`,{});
    },

    getOverviewQueue: async() => {
        return httpClient.get(`/Queue/Overview`, {});
    },
    
    getPaidQueue: async() => {
        return httpClient.get(`/Queue/Paid`, {});
    },
    
    getStaffQueue: async(staffID = null) => {
        if (staffID != null) {
            var query = {
                staffID
            };
            return httpClient.get(`/Queue/Staff/{staffID}`, query);
        } else {
            return httpClient.get(`/Queue/Staff`, {});
        }
    },

    getListStaff: async() => {
        return httpClient.get(`/Queue/ListStaff`, {});
    }
}