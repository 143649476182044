<template>
    <v-card>
        <v-card-title>
             <div class="d-flex w-100 flex-column">
                <div class="d-block">
                    <span class="text-title">{{ $t('Revenue.PersonalQueue') }}</span>
                    <b class="ml-3">
                        [<span class="text-title"> {{ userLogin.fullName }} - <span>{{ $t('Common.Role') }}: </span> {{ userLogin.userTypeName }}</span> ]
                    </b>
                </div>               
            </div>
        </v-card-title>
        
        <v-sheet>

            <div class="d-block pl-4 pr-4">
                <v-row class="justify-space-between align-center">
                    <v-col cols="3">
                        <span>{{ $t('Common.Amount') }}: <b>{{ listPatient.length }}</b></span>
                    </v-col>
                    <v-col cols="3">
                        <div class="d-flex w-100 pl-5 pr-5 align-center">
                             <v-text-field
                                @keyup="search"
                                :label="$t('Button.Searching')"
                                clearable
                                append-icon="mdi-account-search-outline"
                            ></v-text-field>

                            <v-btn fab dark x-small color="primary" class="ml-4"
                                @click="reload"
                            >
                                <v-icon>
                                    mdi-reload
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
                
            </div>
            <!-- LOADING -->
            <v-skeleton-loader
                v-if="loading"
                type="table-thead, table-tbody"
            ></v-skeleton-loader>

            <!-- LIST PATIENT -->
            <div class="d-block w-100 p-2" v-else>
                <v-simple-table fixed-header id="personal-queue">
                    <thead>
                        <tr class="font-weight-bold">
                            <td v-for="(item,index) in titles"  :key="index">{{ item }}</td>
                        </tr>     
                    </thead>
                    <tbody v-if="(listPatient.length > 0)">
                        <tr v-for="(itemPatient, index) in listPatient" :key="index">
                            <td class="order">{{ index + 1 }}</td>
                            <td>
                                <div class="d-block avatar">
                                    <img :src="itemPatient.avatar" @click="avatarPatient.index = index" />
                                </div>
                            </td>
                            <td>{{ itemPatient.fullName }}</td>
                            <td>{{ itemPatient.patientID }}</td>
                            <td>{{ itemPatient.age }}</td>
                            <td>{{ formatDate(itemPatient.timeOrdered) }}</td>
                            <td>
                                <TimeProcess :minutes="itemPatient.minutes" />
                            </td>
                            <td>
                                <StatusProcess :completeID="itemPatient.completeID"/>
                            </td>
                            <td>
                               <v-btn      
                                    depressed
                                    color="primary"
                                    @click="detail(itemPatient.orderID)"
                                >Detail
                                </v-btn>
                                <v-btn
                                    class="ml-4"
                                    @click="finish(itemPatient.orderID)"
                                    depressed
                                    color="error"
                                >Finish</v-btn>
                            </td>
                        </tr>
                    </tbody>
                    
                    <tbody v-else>
                        <tr>
                            <td :colspan="(titles.length + 1)">List is Empty!</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </div>

            <!-- COOLIGHT IMAGE -->
            <CoolLightBox
                :items="avatarPatient.items"
                :index="avatarPatient.index"    
                @close="avatarPatient.index = null"
            >
            </CoolLightBox>
        </v-sheet>

        <!-- Show error -->
        <v-alert
            :type="status.type || 'error'"
            width="100%"
            icon="mdi-cloud-alert"
            dismissible
            v-model="status.show"
            transition="scale-transition"
            >
            {{ status.message }}
        </v-alert>
    </v-card>      
</template>

<script>

import QueueService from '../../../services/queue';
import Moment from 'moment';
import TimeProcess from './components/TimeProcess';
import StatusProcess from './components/StatusProcess';
import FastTestServicer from '../../../services/fastTest';
import { transliterateChar } from '../../../plugins/helper';
import i18n from "@/plugins/i18n";

export default {
    components: {
        TimeProcess,
        StatusProcess
    },
    data: () => {
        return {
            loading: false,
            status: {
                type: 'error',
                show: false,
                message: ''
            }, 
            'titles' : [
                i18n.t('Common.Order'),
                i18n.t('Patient.Avatar'), 
                i18n.t('Patient.Name'), 
                i18n.t('Patient.PID'), 
                i18n.t('Patient.Age'), 
                i18n.t('Common.TimeOrdered'), 
                i18n.t('Common.Minutes'), 
                i18n.t('Common.Status'), 
                i18n.t('Common.Action'), 
            ],
            'userLogin': {
                fullName: '',
                userTypeName: '',
            },
            source: {
                amount: 0,
                listPatient: []
            },
            listPatient: [],

            avatarPatient: {
                items: [],
                index: null
            }
        }
    },
    beforeMount() {
        this.userLogin.fullName = this.$store.getters.userData.fullName;
        this.userLogin.userTypeName = this.$store.getters.userData.userTypeName;
    },

    async mounted() {
        this.getPersonalQueue();
    },

    computed: {
       
    },

    methods: {
        showStatus(msg) { 
            this.status.type = 'error';
            this.status.show = true;
            this.status.message = msg;
        },
        formatDate(date) {
            return Moment(date).format("DD-MM-YYYY h:mm:ss");
        },
        async getPersonalQueue() { 
            // turn on loading
            this.loading = true;
            var result = await QueueService.getStaffQueue();

            if (result.error) {
                this.showStatus(result.error);
                return;
            }

            this.loading = false;
            
            this.source.amount = result.amount;
            this.source.listPatient = result.listPatient;

            this.listPatient = result.listPatient;

            this.mappingAvatar();
        },

        mappingAvatar() { // list patientItem
            if (this.listPatient.length > 0) 
            {   
                this.listPatient.forEach(item => {
                    this.avatarPatient.items.push({ 
                        title: item.fullName,
                        description: item.CompleteId,
                        src: item.avatar
                    });
                });        
            }    
        },

        reload(){
            this.getPersonalQueue();
        },

        async finish(orderID) {
            this.$swal({
                title: 'Are you sure?',
                text: 'Once finished, you will not be able to recover this record!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, finish it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    var response =  await FastTestServicer.updateCompleteStatusOrder(orderID, false);
                    if (response.error) {
                        this.showStatus(response.error);
                        return;
                    }
                    // update source
                    this.source.listPatient = this.source.listPatient.filter((item) => {
                        return item.orderID !== orderID;
                    })
                    
                    this.listPatient = this.source.listPatient;

                    // swal that finished successfully
                    this.$swal('Finished!','You has finished that record','success')
                }
            })     
        },

        search(e) {
            var name = e.target.value;
            if (name.length > 0)
            {
                name = transliterateChar(name).toLowerCase();
               
                var filter = this.source.listPatient.filter(function(item) {
                    
                    let fullName = transliterateChar(item.fullName).toLowerCase();
                    return fullName.includes(name);
                             
                });
               
                this.listPatient = filter;
            } else {
                this.listPatient = this.source.listPatient;
            }
        },

        // view detail personal info
        detail(orderID) {
            this.$emit('viewDetail', orderID)
            
        }
    }
}
</script>

<style scoped>
    #personal-queue .order{
        font-weight: bold;
        text-decoration: underline;
    }

    #personal-queue .avatar {
        padding: 10px;
        height: 60px;
        width: 80px;
        box-sizing: border-box;
    }

    #personal-queue .avatar img {
        max-height: 100%;
        max-width: 100%;
    }

    #personal-queue tr {
        cursor: pointer;
    }
</style>