<template>
    <div class="w-100">
        <v-row>
            <v-col cols="12" style="padding: 2px; height:auto;">
                <v-card id="patient-service">
                   <v-card-title>
                       <div class="controller-task">
                           <v-tabs v-model="tab" background-color="#eaeaea" slider-color="#FFFFFF">
                               <v-tab v-for="(item, index) in listTab" :key="index"
                                active-class="v-tab-active"
                               >
                                   {{ item }}
                               </v-tab>
                           </v-tabs>
                       </div>
                   </v-card-title>

                   <v-card-text >
                       <div id="task-content">
                            <div class="header">
                                <span class="font-weight-bold">Root Canal Treatment</span>

                                <v-btn dense class="mr-2 pl-1 pr-1" color="#ffffff">
                                    <v-icon small class="mr-1 ">
                                        mdi-arrow-up-drop-circle-outline
                                    </v-icon>
                                    Show Previous Treatment
                                </v-btn>
                            </div>

                            <div class="content">
                                <v-timeline dense>
                                    <v-slide-x-reverse-transition v-for="(item, index) in listTimeline" :key="index">
                                        <v-timeline-item small :color="item.color">
                                           <TimelineDetail 
                                                :dateStart="item.dateStart"
                                                :time="item.time"
                                                :treatMent="item.treatMent"
                                                :staffPerform="item.staffPerform"
                                                :staffOrder="item.staffOrder"
                                           />
                                        </v-timeline-item>
                                    </v-slide-x-reverse-transition>      
                                </v-timeline>
                            </div>    
                       </div>
                   </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import TimelineDetail from './Timeline';
export default {
    components: {
        TimelineDetail
    },
    data: () => {
        return {
            tab: null,
            listTab: ['Upcoming Appointments', 'Post Appointments', 'Medical Records'],

            listTimeline: [
                { 
                    color: 'red',
                    dateStart: "15 Dec'20",
                    time: "NOW",
                    staffOrder:'Dr. Bui Thanh Quan',
                    staffPerform: 'Dr. Bui Thanh Quan',
                    treatMent: 'Something ...'
                },
                { 
                    color: 'primary',
                    dateStart: "14 Dec'20",
                    time: "10:00 - 12:00",
                    staffOrder:'Dr. Bui Thanh Quan',
                    staffPerform: 'Dr. Bui Thanh Quan',
                    treatMent: 'Something ...'
                },
                { 
                    color: 'primary',
                    dateStart: "14 Dec'20",
                    time: "10:00 - 12:00",
                    staffOrder:'Dr. Bui Thanh Quan',
                    staffPerform: 'Dr. Bui Thanh Quan',
                    treatMent: 'Something ...'
                },
                { 
                    color: 'primary',
                    dateStart: "14 Dec'20",
                    time: "10:00 - 12:00",
                    staffOrder:'Dr. Bui Thanh Quan',
                    staffPerform: 'Dr. Bui Thanh Quan',
                    treatMent: 'Something ...'
                },
                { 
                    color: 'primary',
                    dateStart: "14 Dec'20",
                    time: "10:00 - 12:00",
                    staffOrder:'Dr. Bui Thanh Quan',
                    staffPerform: 'Dr. Bui Thanh Quan',
                    treatMent: 'Something ...'
                }
            ]

        }
    }
}
</script>

<style scoped>
#patient-service {
    min-height: 42vh;
    background-color: #ffffff;
}
#patient-service  .controller-task {
    padding: 5px;
    width: 70%;
    background-color: #eaeaea;
    border-radius: 5px;
}

#patient-service .v-tab {
    font-size: 12px;
    background-color: #eaeaea;
    padding: 5px 10px;
}

#patient-service .v-tab-active {
    color: #1e88e5;
    background-color: #ffffff;
    border-bottom: none;

}

#task-content {
    width: 100%;
    height: auto;
    
    background-color: #eaeaea;
    border-radius: 5px;
}

#task-content .header {
    display: flex;
    justify-content:space-between !important;
    align-content: center;
    align-items: center;
    padding: 15px;
    width: 100%;
    border-bottom: 0.1px solid #c4c4c4;
}

#task-content .content {
    margin-top: 10px;
    max-height: 50vh;
    padding: 5px 20px;
    overflow: hidden;
}

#task-content .content:hover {
    overflow-y: scroll;
}
#task-content .content::-webkit-scrollbar {
    width: 8px;
    background-color: #F5F5F5; 
}
#task-content .content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #4285F4; 
}

#task-content .content .item-time {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
#task-content .content .item-time .title-time {
    color: #c4c4c4;
    font-weight: 600;
    font-size: 14px;
}

#task-content .content .item-time .value {
    font-weight: 800;
    color: black;
}

#task-content .content .time-episode {
    padding-left: 20px;
    border-right: 2px solid grey;
}

#task-content .content .time-episode .title-time {
    font-size: 18px;
    color: black;
    font-weight: 500;
}

#task-content .content .time-episode .value {
     color: #c4c4c4;
}

</style>