<template>
    <div class="w-100">
        <v-row>
            <v-col cols="12" style="padding: 2px;">
                <v-card id="file-document-patient">
                    <v-card-title class="d-flex justify-space-between align-center">
                        <span class="text-subtitle-1 font-weight-bold">Files / Documents</span>
                        <a class="primary--text text-subtitle-2">
                            <v-icon small color="primary" class="mr-1">mdi-file-document-outline</v-icon>
                            Add files
                        </a>
                    </v-card-title>
                    <v-sheet class="content">
                        <v-list class="padding: 5px 10px">
                            <v-list-item v-for="(item, index) in documents" :key="index">
                                <v-list-item-icon>
                                    <v-icon>
                                        mdi-file-document-outline
                                    </v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-icon>
                                    <v-icon class="mr-1">
                                        mdi-beaker-remove-outline
                                    </v-icon>
                                    <v-icon class="ml-1">
                                        mdi-arrow-down-bold-circle-outline
                                    </v-icon>
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list>
                    </v-sheet>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data: () => {
        return {
            documents: [
                {
                    name: 'Check Up Result.pdf',
                    exist: true
                },
                {
                    name: 'Tonometer Result.pdf',
                    exist: true
                },
                {
                    name: 'Objective Rx.pdf',
                    exist: true
                },
                {
                    name: 'Subjective Rx.pdf',
                    exist: true
                },
                {
                    name: 'Cover test.pdf',
                    exist: true
                }
            ]
        }
    }
}
</script>

<style scoped>
#file-document-patient .content {
    width: 100%;
    padding: 0 15px;
    overflow: auto;
    max-height: 50vh;
}

#file-document-patient .v-list-item__icon {
    display: flex;
    justify-content: center;
    align-items: center;
}
#file-document-patient .v-list-item {
    margin: 10px 0;
    cursor: pointer;
    font-size: 14px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.19);
}

#file-document-patient .v-list-item:hover {
    background-color: #e2e5e9;
}

#file-document-patient .content::-webkit-scrollbar {
    width: 8px;
    background-color: #F5F5F5; 
}

#file-document-patient .content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #4285F4; 
}
</style>