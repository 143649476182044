import HttpClient from '../plugins/httpClient';


import { ApiGateway } from '../plugins/gateway';

var httpClient = new HttpClient(ApiGateway.Emr);

export default {
    getListItemWithType: async (typeID) => {
        var query = {
            typeID
        };
        
        return httpClient.get(`/Order/Count/{typeID}`, query);
    },

    getListPatientWithItemID: async (data) => {
        return httpClient.post(`/Order/ListOrdered`, {}, data);
    },

    updateCompleteStatusOrder: async(orderID, service) => {
        var query = {
            service: service,
        } 
        return httpClient.put(`/Order/Complete/${orderID}`, query);
    }

}