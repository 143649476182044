<template>
  <v-card id="patient-process" elevation="0">
    <div class="d-flex justify-space-between align-center w-100">
      <div class="h-auto">
        <v-btn color="primary" small depressed elevation="2" @click="openQueue">
          <v-icon>mdi-clipboard-list-outline</v-icon>
          <b class="ml-2">Open Queue list</b>
        </v-btn>
      </div>
      <div class="h-auto">
        <v-btn elevation="2" color="#ffffff" small depressed>
          <v-icon small> mdi-printer </v-icon>
        </v-btn>
        <!-- Edit profile -->
        <v-btn class="ml-1" elevation="2" color="#ffffff" small depressed>
          <span>
            <v-icon small> mdi-square-edit-outline </v-icon>
            Edit profile
          </span>
        </v-btn>

        <!-- Finish -->
        <v-btn
          class="ml-1"
          elevation="2"
          color="error"
          small
          depressed
          @click="finish"
        >
          <span> Finish </span>
        </v-btn>
      </div>
    </div>

    <v-row>
      <v-col lg="9" sm="12" sx="12">
        <PatientInfo :patientInfo="patientInformation" class="d-print-none" />
        <v-row v-if="status.show">
          <v-col cols="12">
            <v-alert
              :type="status.type || 'error'"
              icon="mdi-cloud-alert"
              dismissible
              v-model="status.show"
              transition="scale-transition"
            >
              {{ status.message }}
            </v-alert>
          </v-col>
        </v-row>
        <DetailService class="mt-3" />
      </v-col>

      <v-col lg="3" sm="12" sx="12">
        <Notes class="pl-2 pr-2" />

        <Document class="mt-3 pl-2 pr-2" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import OrderService from "../../../services/order";
import PatientInfo from "./components/PatientInfoTest/PatientInfo";
import Notes from "./components/PatientInfoTest/Notes";
import Document from "./components/PatientInfoTest/Document";
import DetailService from "./components/PatientInfoTest/DetailService";
import FastTestServicer from "../../../services/fastTest";
export default {
  components: {
    PatientInfo,
    Notes,
    Document,
    DetailService,
  },

  data: () => {
    return {
      status: {
        type: "error",
        message: "",
        show: false,
      },
      patientInformation: {
        fullName: "",
        avatar: "",
        age: 0,
        address: "",
        genderType: 0,
        birthDate: "",
        email: "",
        identificationNumber: "",
        nationality: "",
        phone: 0,
        admissionType: "",
        registerDate: "",
      },
    };
  },

  props: ["orderID"],

  mounted() {
    if (this.orderID == null) {
      return;
    } else {
      this.loadRecord();
    }
  },
  methods: {
    showStatus(msg) {
      this.status.type = "error";
      this.status.show = true;
      this.status.message = msg;
    },

    openQueue() {
      this.$emit("openQueue");
    },

    async loadRecord() {
      var result = await OrderService.orderInformation(this.orderID);
      if (result.error) {
        this.showStatus(result.error);
        return;
      }
      this.patientInformation = result;
    },

    async finish() {
      this.$swal({
        title: "Are you sure?",
        text: "Once finished, you will not be able to recover this record!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, finish it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var response = await FastTestServicer.updateCompleteStatusOrder(
            this.orderID,
            false
          );
          if (response.error) {
            this.showStatus(response.error);
            return;
          }

          // swal that finished successfully
          this.$swal("Finished!", "You has finished that record", "success");
        }
      });
    },
  },
};
</script>

<style scoped>
#patient-process {
  background-color: #eef5f9;
}
</style>