<template>
  <v-chip small :color="color" :text-color="textColor" style="font-weight: 500">
    {{ title }}
  </v-chip>
</template>

<script>
import i18n from "@/plugins/i18n";

export default {
  data: () => {
    return {
      completeStatus: [
        {
          id: 1,
          title: i18n.t("Common.Waiting"),
          color: "orange",
          textColor: "#ffffff",
        },
        {
          id: 2,
          title: i18n.t("Common.InProcess"),
          color: "primary",
          textColor: "#ffffff",
        },
        {
          id: 3,
          title:i18n.t("Common.Success"),
          color: "green",
          textColor: "#ffffff",
        },
      ],
      color: "orange",
      title: "",
      textColor: "#ffffff",
    };
  },

  props: ["completeID"],

  created() {
    this.color = "primary";
    var data = () => {
      var statusInstance = this.completeStatus.find(
        (item) => item.id == this.completeID
      );
      this.color = statusInstance.color;
      this.title = statusInstance.title.toUpperCase();
      this.textColor = statusInstance.textColor;
    };
    data();
  },
};
</script>

<style scoped>
</style>