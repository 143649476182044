<template>
    <div class="w-100">
        <v-row>
            <v-col cols="12" style="padding: 2px;">
                <v-card>
                    <v-card-title class="d-flex justify-space-between align-center">
                        <span class="text-subtitle-1 font-weight-bold">Notes</span>
                        <a class="primary--text text-subtitle-2">See all</a>
                    </v-card-title>

                    <v-sheet>
                        <div id="note">
                            <div class="content">
                                <div class="comment">
                                    <textarea rows="4">
                                        - The patient
                                      
                                    </textarea>
                                </div>
                                <div class="action">
                                    <v-btn color="primary" small>
                                        save notes
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </v-sheet>

                    <v-sheet>
                        <div id="staff-perform">
                            <span style="font-weight: 600">Staff Perform</span>
                            <div class="handle pt-1 pb-1">
                                <span class="staff-handle ">
                                    <v-icon>
                                        mdi-account-outline
                                    </v-icon>
                                    <b class="ml-1">Dr. Bùi Thanh Quân</b>
                                </span>

                                <span class="time">20 Dec 2020</span>
                            </div>
                        </div>
                    </v-sheet>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
#note {
    min-height: 15vh;
    height: auto;
    padding: 5px;
}
#note .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: #eaeaea;
    border-radius: 5px;
    position: relative;
}

#note .content .action {
    padding: 10px;
    width: 100%;
    color: white;
    text-align: right;
}

#note .comment textarea {
    width: 100%;
    resize: none;
    overflow: auto;
    padding: 0 10px;
}

#note .comment textarea:focus {
    outline: none;
}

#note .comment {
    padding: 10px;
    height: auto;

}

#staff-perform {
    padding: 16px;
    display:flex;
    flex-direction: column;
}

#staff-perform .staff-handle b{
    font-size: 14px;
    color: #a4abb2;
    font-weight: 500;
}

#staff-perform .time {
    font-size: 14px;
    color: #a4abb2;
    font-weight: 500;
    float: right;
}

textarea::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5; 
}

textarea::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #4285F4; 
}
</style>