<template>
    <v-main class="pt-0 pl-2 pr-2" light>
        <v-row>
            <v-col cols="12">
                <PersonalQueue v-if="open.personalQueue" v-on:viewDetail="viewDetail"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <TestPatientInfo v-if="open.patientInfo" 
                    v-on:openQueue="openListQueue"
                    :orderID="currentOrderID"
                />
            </v-col>
      </v-row>
  </v-main>
</template>

<script>

import PersonalQueue from '../../components/commonComponents/Queue/PersonalQueue';
import TestPatientInfo from '../../components/commonComponents/Queue/TestPatientInfo';
export default {
    
    components: {
        PersonalQueue,
        TestPatientInfo
    },
    data () {
        return {
            open: {
                patientInfo: false,
                personalQueue: true
            },

            currentOrderID: null,
        }
    },

    methods: {
        viewDetail(orderID) { 
        
            this.open.personalQueue = !this.open.personalQueue;
            this.open.patientInfo = !this.open.patientInfo;
            // load data here
            this.currentOrderID = orderID;
        },

        openListQueue() {
            this.open.personalQueue = !this.open.personalQueue;
            this.open.patientInfo = !this.open.patientInfo;
        }
    }
}
</script>

<style>
  
</style>