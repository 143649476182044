import HttpClient from '../plugins/httpClient';


import { ApiGateway } from '../plugins/gateway';

var httpClient = new HttpClient(ApiGateway.Emr);

export default {
    orderInformation: async (orderID) => {
        var query = {
            orderID
        };
        return httpClient.get(`/Order/Get/Information/{orderID}`, query);
    }
}