<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="2">
          <div class="item-time time-episode">
            <span class="title-time">{{ dateStart }}</span>
            <span class="value">{{ time }}</span>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="item-time">
            <span class="title-time">Treatment</span>
            <span class="value">{{ treatMent }}</span>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="item-time">
            <span class="title-time">Staff Ordered</span>
            <span class="value">{{ staffOrder }}</span>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="item-time">
            <span class="title-time">Staff performed</span>
            <span class="value">{{ staffPerform }}</span>
          </div>
        </v-col>
        <v-col cols="1">
          <div class="item-time justify-center align-center">
            <v-btn icon color="primary">
              <v-icon>mdi-account-search</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["dateStart", "time", "treatMent", "staffPerform", "staffOrder"],
};
</script>

<style scope>
#task-content .content .item-time {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
#task-content .content .item-time .title-time {
  color: #c4c4c4;
  font-weight: 600;
  font-size: 14px;
}

#task-content .content .item-time .value {
  font-weight: 800;
  color: black;
}

#task-content .content .time-episode {
  padding-left: 20px;
  border-right: 2px solid grey;
}

#task-content .content .time-episode .title-time {
  font-size: 18px;
  color: black;
  font-weight: 500;
}

#task-content .content .time-episode .value {
  color: #c4c4c4;
}
</style>